import React from 'react'
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 250,
    },
});
const DistanceElement = (props) => {
    const classes = useStyles();
    const {data} = props;

    return (
        <div className='distanceList custom-elegant-font'>
        <div className="col-sm-12 col-lg-12">
        <TableContainer className='shadow-none' component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableBody>
                    {data.map((data) => (
                        <TableRow key={data.name} className='custom-table-row distance'>
                            <TableCell component="th" scope="row"><span className='custom-elegant-font'>{data.name}</span></TableCell>
                            <TableCell align="right"><span className='custom-elegant-font'>{data.distance}</span></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </div>
    </div>
    ) 
}

export default withTranslation()(DistanceElement)
