export const data = {
    name: "one",
    title: "Villa Fortuna",
    imgNumbers: '01',
    photo: 'images/banner-villa1.JPG',
    startingPrice: "400",
    bedrooms: "04",
    bathrooms: "05",
    area: "210",
    guests: "08",
    prices:[
        {"date":"1.5. - 11.6.","night":"360","week":"2520"},
        {"date":"12.6. - 25.6.","night":"450","week":"3150"},
        {"date":"26.6. - 2.7.","night":"576","week":"4032"},
        {"date":"3.7. - 27.8.","night":"720","week":"5040"},
        {"date":"28.8. - 3.9.","night":"576","week":"4032"},
        {"date":"4.9. - 17.9.","night":"450","week":"3150"},
        {"date":"18.9. - 31.10.","night":"360","week":"2520"},
    ]
}

