import React from 'react';
import {withTranslation} from "react-i18next";

const AboutUs = (props) => {

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'
    let data = {
        "subtitle": `${props.t('aboutUs')}`,
        "title": `${props.t('twoStoneVillas')}`,
        "content": `${props.t('tsvDesc')}`,
        "image": "house-images/Villa-Carmen-swimming-pool2.webp"
    }


    return <div className="about-area pd-bottom-90">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                    <div className="shape-image-list-wrap">
                        <div className="shape-image-list left-top">
                            <img className="shadow-img" src={publicUrl + data.image} alt={imagealt}/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 align-self-center">
                    <div className="section-title pd-left mb-0">
                        <h5 className="custom-elegant-font">{data.subtitle}</h5>
                        <h2 className="title">{data.title}</h2>
                        <p className='custom-elegant-font custom-color'>{data.content}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default withTranslation()(AboutUs)