import React from 'react';
import {Link} from 'react-router-dom';
import {withTranslation} from "react-i18next";

const Page_header = (props) => {

    let HeaderTitle = props.headertitle;
    let publicUrl = process.env.PUBLIC_URL + '/'
    let Subheader = props.subheader ? props.subheader : HeaderTitle
    const inlineStyle = {
        backgroundImage: 'url(' + publicUrl + `${props.photo})`
    }

    return (
        <div className="breadcrumb-area jarallax" style={inlineStyle}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner">
                            <h1 className="page-title custom-elegant-font">{HeaderTitle}</h1>
                            <ul className="page-list custom-elegant-font">
                                <li><Link to="/"> {props.t('home')}</Link></li>
                                <li>{Subheader}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default withTranslation()(Page_header)