import img1 from '../assets/house-images/Villa-Carmen-bedroom.webp'
import img2 from '../assets/house-images/Villa-Carmen-bedroom-view.webp'
import img3 from '../assets/house-images/Villa-Carmen-bathroom1.webp'
import img4 from '../assets/house-images/Villa-Carmen-bedroom1-bed.webp'
import img5 from '../assets/house-images/Villa-Carmen-bedroom-view2.webp'
import img6 from '../assets/house-images/Villa-Carmen-garden.webp'
import img7 from '../assets/house-images/Villa-Carmen-garden2.webp'
import img8 from '../assets/house-images/Villa-Carmen-dining-table.webp'
import img9 from '../assets/house-images/Villa-Carmen-terrace.webp'
import img10 from '../assets/house-images/Villa-Carmen-garden-at-nigth.webp'
import img11 from '../assets/house-images/Villa-Carmen-outside-launge.webp'
import img12 from '../assets/house-images/Villa-Carmen-Swimming-pool.webp'
import img13 from '../assets/house-images/Villa-Carmen-sauna.webp'
import img14 from '../assets/house-images/Villa-Carmen-side-entrance.webp'
import img15 from '../assets/house-images/Villa-Carmen-bedroom2.webp'
import img16 from '../assets/house-images/Villa-Carmen-bedroom2-bathroom.webp'
import img17 from '../assets/house-images/Villa-Carmen-balcony.webp'
import img18 from '../assets/house-images/Villa-Carmen-inner-lounge.webp'
import img19 from '../assets/house-images/Villa-Carmen-dining-room.webp'
import img20 from '../assets/house-images/Villa-Carmen-main-bathroom.webp'
import img21 from '../assets/house-images/Villa-Carmen-main-bathroom2.webp'
import img22 from '../assets/house-images/Villa-Carmen-outside-dining-area.webp'
import img23 from '../assets/house-images/Villa-Carmen-balcony-view.webp'
import img24 from '../assets/house-images/Villa-Carmen-bathroom3.webp'
import img25 from '../assets/house-images/Villa-Carmen-bedroom3.webp'
import img26 from '../assets/house-images/Villa-Carmen-bird-view.webp'
import img27 from '../assets/house-images/Villa-Carmen-bird-view-night.webp'
import img28 from '../assets/house-images/Villa-Carmen-night.webp'
import img29 from '../assets/house-images/Villa-Carmen-night2.webp'
import img30 from '../assets/house-images/Villa-Carmen-night-garden.webp'
import img31 from '../assets/house-images/Villa-Carmen-house-night.webp'
import img32 from '../assets/house-images/Villa-Carmen-outside-lounge-detail.webp'
import img33 from '../assets/house-images/Villa-Carmen-Swimming-pool-terrace.webp'
import img34 from '../assets/house-images/Villa-Carmen-sun-beds-pool.webp'
import img35 from '../assets/house-images/Villa-Carmen-swimming-pool-detail.webp'
import img36 from '../assets/house-images/Villa-Carmen-swimming-pool2.webp'
import img37 from '../assets/house-images/Villa-Carmen-dining-table-night.webp'
import img38 from '../assets/house-images/Villa-Carmen-dining-table-night2.webp'
import img39 from '../assets/house-images/Villa-Carmen-dining-area.webp'
import img40 from '../assets/house-images/Villa-Carmen-dining-area-detail.webp'
import img41 from '../assets/house-images/Villa-Carmen-dining-area2.webp'
import img42 from '../assets/house-images/Villa-Carmen-launge-night.webp'
import img43 from '../assets/house-images/Villa-Carmen-lounge-detail.webp'
import img44 from '../assets/house-images/Villa-Carmen-grill.webp'
import img45 from '../assets/house-images/Villa-Carmen-dining-detail.webp'
import img46 from '../assets/house-images/Villa-Carmen-inner-lounge2.webp'
import img47 from '../assets/house-images/Villa-Carmen-TV.webp'
import img48 from '../assets/house-images/Villa-Carmen-bathorm-detail.webp'

export const photos = [
    {
        src: img1,
        width: 3,
        height: 2
    },

    {
        src: img2,
        width: 3,
        height: 2
    },

    {
        src: img3,
        width: 3,
        height: 2
    },

    {
        src: img4,
        width: 3,
        height: 2
    },

    {
        src: img5,
        width: 3,
        height: 2
    },

    {
        src: img6,
        width: 3,
        height: 2
    },

    {
        src: img7,
        width: 3,
        height: 2
    },

    {
        src: img8,
        width: 3,
        height: 2
    },

    {
        src: img9,
        width: 3,
        height: 2
    },

    {
        src: img10,
        width: 3,
        height: 2
    },

    {
        src: img11,
        width: 3,
        height: 2
    },

    {
        src: img12,
        width: 3,
        height: 2
    },

    {
        src: img13,
        width: 3,
        height: 2
    },

    {
        src: img14,
        width: 3,
        height: 2
    },

    {
        src: img15,
        width: 3,
        height: 2
    },

    {
        src: img16,
        width: 3,
        height: 2
    },

    {
        src: img17,
        width: 3,
        height: 2
    },

    {
        src: img18,
        width: 3,
        height: 2
    },

    {
        src: img19,
        width: 3,
        height: 2
    },

    {
        src: img20,
        width: 3,
        height: 2
    },

    {
        src: img21,
        width: 3,
        height: 2
    },

    {
        src: img22,
        width: 3,
        height: 2
    },

    {
        src: img23,
        width: 3,
        height: 2
    },

    {
        src: img24,
        width: 3,
        height: 2
    },

    {
        src: img25,
        width: 3,
        height: 2
    },

    {
        src: img26,
        width: 3,
        height: 2
    },

    {
        src: img27,
        width: 3,
        height: 2
    },

    {
        src: img28,
        width: 3,
        height: 2
    },

    {
        src: img29,
        width: 3,
        height: 2
    },

    {
        src: img30,
        width: 3,
        height: 2
    },

    {
        src: img31,
        width: 3,
        height: 2
    },

    {
        src: img32,
        width: 3,
        height: 2
    },

    {
        src: img33,
        width: 3,
        height: 2
    },

    {
        src: img34,
        width: 3,
        height: 2
    },

    {
        src: img35,
        width: 3,
        height: 2
    },

    {
        src: img36,
        width: 3,
        height: 2
    },

    {
        src: img37,
        width: 3,
        height: 2
    },

    {
        src: img38,
        width: 3,
        height: 2
    },

    {
        src: img39,
        width: 3,
        height: 2
    },

    {
        src: img40,
        width: 3,
        height: 2
    },

    {
        src: img41,
        width: 3,
        height: 2
    },

    {
        src: img42,
        width: 3,
        height: 2
    },

    {
        src: img43,
        width: 3,
        height: 2
    },

    {
        src: img44,
        width: 3,
        height: 2
    },

    {
        src: img45,
        width: 3,
        height: 2
    },

    {
        src: img46,
        width: 3,
        height: 2
    },

    {
        src: img47,
        width: 3,
        height: 2
    },

    {
        src: img48,
        width: 3,
        height: 2
    },
];
