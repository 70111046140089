import React from 'react';
import {Link} from 'react-router-dom';
import LanguageSelector from "../../helpers/LanguageSelector";
import {withTranslation} from "react-i18next";

const Navbar = (props) => {

  return (
      <div>
        <div className="navbar-area navbar-area-fixed">
          <nav className="navbar navbar-area navbar-expand-lg navbar-area-fixed">
            <div className="container nav-container">
              <div className="responsive-mobile-menu">
                <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse"
                        data-target="#realdeal_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="icon-left"/>
                  <span className="icon-right"/>
                </button>
              </div>
              <div className="">
                <Link to="/"><span className='custom-logo-font'>Villa Carmen</span></Link>
              </div>

              <div className="collapse navbar-collapse" id="realdeal_main_menu">
                <ul className="navbar-nav menu-open readeal-top">
                  <li className='custom-link'><Link to="/"><span className='custom-elegant-font hover-nav'>{props.t('home')}</span></Link></li>
                  <li className='custom-link'><Link to="/about"><span className='custom-elegant-font hover-nav'>{props.t('about')}</span></Link></li>
                  <li className='custom-link'><Link to="/villa"><span className='custom-elegant-font hover-nav'>Villa</span> </Link></li>
                  <li className='custom-link'><Link to="/gallery"><span className='custom-elegant-font hover-nav'>{props.t('gallery')}</span></Link></li>
                  <li className='custom-link'><Link to="/contact"><span className='custom-elegant-font hover-nav'>{props.t('contact')}</span></Link></li>
                  <li><LanguageSelector/></li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
  )
}


export default withTranslation()(Navbar)