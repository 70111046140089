import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {withTranslation} from "react-i18next";

const useStyles = makeStyles({
    table: {
        minWidth: 250,
    },
});

const PriceList = props => {
    const classes = useStyles();

    return(
        <div className='priceList custom-elegant-font'>
            <h4 className='m-0'>{props.t('pricesfy') + " " + new Date().getFullYear()}</h4>
            <small className='font-italic'>* {props.t('minRes')}</small>
            <TableContainer className='shadow-none' component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><span className='font-weight-bold custom-elegant-font'>{props.t('date')}</span></TableCell>
                            <TableCell align="right"><span className='font-weight-bold custom-elegant-font text-capitalize'>{props.t('night')}</span></TableCell>
                            <TableCell align="right"><span className='font-weight-bold custom-elegant-font text-capitalize'>{props.t('week')}</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.prices.map((row) => (
                            <TableRow key={row.date} className='custom-table-row '>
                                <TableCell component="th" scope="row"><span className='custom-elegant-font'>{row.date}</span></TableCell>
                                <TableCell align="right"><span className='custom-elegant-font'> &euro;{row.night}</span></TableCell>
                                <TableCell align="right"><span className='custom-elegant-font'>&euro;{row.week}</span></TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default withTranslation()(PriceList)

