import React from 'react'
import { withTranslation } from 'react-i18next';
import DistanceElement from './distance-element';



const Distance = (props) => {

    const data1 = [
        {"name":`${props.t('airport')}`,"distance":"120 km"},
        {"name":`${props.t('bank')}`,"distance":"8 km"},
        {"name":`${props.t('ATM')}`,"distance":"150 m"},
        {"name":`${props.t('beach')}`,"distance":"1 m"},
        {"name":`${props.t('bus')}`,"distance":"4 km"},
    ]
    const data2 = [
        {"name":`${props.t('caffe')}`,"distance":"30 m"},
        {"name":`${props.t('centre')}`,"distance":"4 km"},
        {"name":`${props.t('dentist')}`,"distance":"4 km"},
        {"name":`${props.t('doctor')}`,"distance":"4 km"},
        {"name":`${props.t('ferry')}`,"distance":"17 km"},
    ]
    const data3 = [
        {"name":`${props.t('marina')}`,"distance":"17 km"},
        {"name":`${props.t('marketplace')}`,"distance":"4 km"},
        {"name":`${props.t('pharmacy')}`,"distance":"4 km"},
        {"name":`${props.t('restaurants')}`,"distance":"500 m"},
        {"name":`${props.t('shops')}`,"distance":"150 m"},
    ]
    const data4 = [
        {"name":`${props.t('tennis')}`,"distance":"3 km"},
        {"name":`${props.t('sea')}`,"distance":"10 m"},
        {"name":`${props.t('gasstation')}`,"distance":"17 km"},
        {"name":`${props.t('info')}`,"distance":"4 km"},
    ] 




    return (
        <div /* style={{height:'500px'}} */ className="container">
        <h3 className="title">{props.t('distanceTo')}</h3>
        <div className="row sm-4 distance">
        <DistanceElement data={data1}/>
        <DistanceElement data={data2}/>
        <DistanceElement data={data3}/>
        <DistanceElement data={data4}/>
        </div>
        </div>
          )
}

export default withTranslation()(Distance)
