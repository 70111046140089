import React from 'react';
import {withTranslation} from "react-i18next";

const Map = (props) => {
    return (
        <div className="container">
        <div style={{width:'inherit'}} className='map'>
            <h3>{props.t('ourLocation')}</h3>
            <iframe
                title="map"
                className='w-100 h-100 border-0'
                src="https://maps.google.com/maps?q=42%C2%B054'20.3%22N%2016%C2%B047'44.7%22E&t=k&z=15&ie=UTF8&iwloc=&output=embed"
                allowFullScreen="" loading="lazy"/>
        </div>
        </div>

    )
}

export default withTranslation()(Map)

