import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import {withTranslation} from "react-i18next";

const Banner = (props) => {

    let publicUrl = process.env.PUBLIC_URL + '/'

    const inlineStyle = {
        backgroundImage: 'url(' + publicUrl + 'images/banner-home.JPG)'
    }

    return <div>
        <div className="banner-area jarallax" style={inlineStyle}>
            <div className="container">
                <div className="banner-inner-wrap">
                    <div className="text-center w-100">
                        <h1 className="custom-banner-title ">{props.t('titleH')}</h1>
                        <div className="btn-wrap text-center">
                            <Link className="btn btn-yellow mr-2" to='#more'>{props.t('findMore')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default withTranslation()(Banner)