import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

const Footer_v1 = () => {

    useEffect(
        () => {

            let publicUrl = process.env.PUBLIC_URL+'/'
            const minscript = document.createElement("script");
            minscript.async = true;
            minscript.src = publicUrl + "assets/js/main.js";

            document.body.appendChild(minscript);
        }
    )

    return (
        <footer className="footer-area">
            <div className="container">
                <div className="footer-top">
                    <div className="row">
                        <div className="col-sm-4">
                            <Link to="/"><span className='custom-logo-font'>Villa Carmen</span></Link>

                        </div>
                        <div className="col-sm-8 align-self-center">
                            <div className="footer-social text-sm-right">
                                <a href="http://korculastay.com/" className='font-weight-bold'>&copy; KorculaStay</a> all rights reserved
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}


export default Footer_v1