import React from 'react';
import {withTranslation} from "react-i18next";

const Mission = (props) => {
    let data = [
        {
            "number": "01",
            "title": `${props.t('reserve')}`,
            "content": `${props.t('reserveText')}`
        },
        {
            "number": "02",
            "title": `${props.t('stay')}`,
            "content": `${props.t('stayText')}`,
            "class": "offset-xl-1"
        },
        {
            "number": "03",
            "title": `${props.t('never')}`,
            "content": `${props.t('neverText')}`,
            "class": "offset-xl-1"
        }
    ]

    return <div className="mission-vission-area pd-top-80 pd-bottom-70">
        <div className="container">
            <div className="row justify-content-center">
                {data.map((item, i) =>
                    <div key={i} className="col-xl-3 col-lg-4 col-sm-6">
                        <div className="single-intro text-lg-left text-center">
                            <div className="text">
                                {item.number}
                            </div>
                            <div className="details">
                                <h4 className="title">{item.title}</h4>
                                <p>{item.content}</p>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    </div>
}

export default withTranslation()(Mission)