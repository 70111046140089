import React from 'react';
import {withTranslation} from "react-i18next";

const Details = (props) => {
  let publicUrl = process.env.PUBLIC_URL + '/'

  return (
      <div>
        <div className="news-details-area">
          <div className="container">
            <div className="news-details-author-social">
              <div className="row">
              </div>
            </div>
            <div className="row justify-content-center pd-top-70">
              <div className="col-lg-8">
                <div className="news-details-wrap ">
                  <h2 className="title1" style={{textAlign:"center"}}>{props.t('desc')}</h2>
                  <p className='text-justify'>{props.t('descContent')}</p>
                  <img className="rounded-lg" src={publicUrl + "images/velaluka.jpg"} alt="img"/>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default withTranslation()(Details);
