import React from 'react';
import {Link} from "react-router-dom";
import PriceList from "./priceList";
import {withTranslation} from "react-i18next";
import Facilities from "../components/Facilities";

const PropertyDetails = (props) => {

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'
    const data = props.data;
    console.log(data)
    return <div className="property-details-area">
        <Facilities />

        <div className="bg-gray pd-top-100 pd-bottom-90">

            <div className="container">
                <div className="row ">
                    <div className="col-xl-9 col-lg-8">
                        <img src={publicUrl + `images/Villa-Carmen-swimming-pool2.webp`} alt={imagealt}/>
                    </div>
                    <div className="col-xl-3 col-lg-4">
                        <div className="widget widget-owner-info mt-lg-0 mt-5">
                            <div className="contact-info">
                                <h6 className="mb-3">{props.t('cInfo')}</h6>
                                <div className="media">
                                    <div className="media-left">
                                        <i className="fa fa-map-marker"/>
                                    </div>
                                    <div className="media-body">
                                        <p>{props.t('address')}</p>
                                        <span>Prižba, Korčula</span>
                                    </div>
                                </div>
                                <div className="media mb-0">
                                    <div className="media-left">
                                        <i className="fa fa-envelope"/>
                                    </div>
                                    <div className="media-body">
                                        <p>{props.t('email')}</p>
                                        <span>info@korculastay.com</span>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="banner-btn-wrap mt-2">
                            <Link className="btn btn-yellow mr-2" to='/contact'>{props.t('contactUs')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className='flex custom-star-rating'>
                <span className='fa fa-star-o mx-1'/>
                <span className='fa fa-star-o mx-1'/>
                <span className='fa fa-star-o mx-1'/>
                <span className='fa fa-star-o mx-1'/>
                <span className='fa fa-star-o mx-1'/>
            </div>
            <div className="row pd-top-90">
                <div className="col-lg-9">
                    <div className="property-info mb-5">
                        <div className="row">
                            <div className="col-md-3 col-sm-6">
                                <div className="single-property-info">
                                    <h5>{props.t('bedrooms')}</h5>
                                    <p><i className="fa fa-bed"/>{data.bedrooms}</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-property-info">
                                    <h5>{props.t('bathrooms')}</h5>
                                    <p><i className="fa fa-bath"/>{data.bathrooms}</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-property-info">
                                    <h5>{props.t('area')}</h5>
                                    <p><i className="fa fa-home"/>{data.area} &#13217;</p>
                                    {/*<p><img src={publicUrl + "/assets/img/icons/7.png"} alt={imagealt}/>{data.area} &#13217;</p>*/}
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-property-info">
                                    <h5>{props.t('guests')}</h5>
                                    <p><i className="fa fa-user"/>{data.guests} Max</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="property-news-single-card style-two border-bottom-yellow">
                        <h4>{props.t('houseDescTitle')}</h4>
                        <p className='text-justify'>{data.name === "one" ? props.t('houseDesc') : props.t('houseDesc2')}</p>
                    </div>
                    <div className="property-news-single-card border-bottom-yellow">
                        <h4>Amenities</h4>
                        <div className="row">
                            <div className="col-sm-4">
                                <ul className="rld-list-style mb-3 mb-sm-0">
                                    <li><i className="fa fa-fire"/>{props.t('grill')}</li>
                                    <li><i className="fa fa-check"/>{props.t('pool')}</li>
                                    <li><i className="fa fa-television"/>{props.t('satv')}</li>
                                    <li><i className="fa fa-wifi"/>{props.t('wifi')}</li>
                                    <li><i className="fa fa-bolt"/>{props.t('AC')}</li>
                                    <li><i className="fa fa-check"/>{props.t('gym')}</li>
                                </ul>
                            </div>
                            <div className="col-sm-4">
                                <ul className="rld-list-style mb-3 mb-sm-0">
                                    <li><i className="fa fa-check"/>{props.t('pets')}</li>
                                    <li><i className="fa fa-car"/>{props.t('parking')}</li>
                                    <li><i className="fa fa-sun-o"/>{props.t('srvcT3')}</li>
                                    <li><i className="fa fa-shower"/>{props.t('showerOut')}</li>
                                    <li><i className="fa fa-fire"/>{props.t('fireplace')}</li>
                                    <li><i className="fa fa-snowflake-o"/>{props.t('airCon')}</li>
                                </ul>
                            </div>
                            <div className="col-sm-4">
                                <ul className="rld-list-style mb-3 mb-sm-0">
                                    <li><i className="fa fa-umbrella"/>{props.t('terrace')}</li>
                                    <li><i className="fa fa-bed"/>{props.t('bigBed')}</li>
                                    <li><i className="fa fa-shower"/>{props.t('showerIn')}</li>
                                    <li><i className="fa fa-check"/>{props.t('kitchen')}</li>
                                    <li><i className="fa fa-snowflake-o"/>{props.t('fridge')}</li>
                                    <li><i className="fa fa-check"/>{props.t('sauna')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="property-news-single-card  pb-3">
                        <PriceList prices={data.prices}/>
                    </div>
                </div>
            </div>
        </div>
    </div>


}

export default withTranslation()(PropertyDetails)