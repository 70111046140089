import React from 'react';
import {withTranslation} from "react-i18next";

const Client = (props) => {

    const data = {
        "title1": `${props.t('Client.title1')}`,
        "title2": `${props.t('Client.title2')}`,
        "items":[
            {
                "name":"Max McRae",
                "image":"assets/img/client/man.png",
                "content":"Absolutely beautiful beach front Villa in Prižba, Korcula Island. The location is quite remote about 45 minutes drive from Korcula. The house has numerous spaces for entertainment and relaxation such as poolside, outdoor dining and BBQ area. The beds are super comfortable and there is everything one could possibly need. Perfect holiday location for a family or couples wanting to relax and enjoy the house and the beautiful beach. "
            },
            {
                "name":"Chloe Gia James",
                "image":"assets/img/client/woman.png",
                "content":"For a vacation to be perfect it takes several things: good accommodation, good company, and good weather. All this was perfect. Both us and our friends together with two small children, we agree that this house is spectacular."
            }
        ]
    }

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'

    return <div className='client-area pb-5 pt-5'>
        <div className="container">
            <div className="section-title text-center">
                <h2 className="title">{data.title1} <br/> {data.title2}</h2>
            </div>
            <div className="client-review-img">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-7 col-md-10">
                        <div className="client-slider-2 text-center">
                            {data.items.map((item, i) =>
                                <div key={i} className="item">
                                    <div className="single-client-review">
                                        <div className="thumb">
                                            <img src={publicUrl + item.image} alt={imagealt} style={{height:"75px",width:"75px"}}/>
                                        </div>
                                        <div className="review-details ">
                                            <p className='text-justify px-1'>{item.content}</p>
                                            <h4>{item.name}</h4>
                                            <p>{item.designation}</p>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default withTranslation()(Client)