import React from 'react';
import {withTranslation} from "react-i18next";
import {Link} from 'react-router-dom';

import UserIcon from "../../../../assets/icons/userIcon.png";
import BedIcon from "../../../../assets/icons/bed.png";
import BathroomIcon from "../../../../assets/icons/bathroom.png";
import BlueprintIcon from "../../../../assets/icons/blueprint.png";

const data = [
    {
        "iconName":"user",
        "iconSrc":UserIcon,
    },
    {
        "iconName":"bed",
        "iconSrc":BedIcon,
    },
    {
        "iconName":"bathroom",
        "iconSrc":BathroomIcon,
    },
    {
        "iconName":"blueprint",
        "iconSrc":BlueprintIcon
    }
]


const ShortAmenity = (props) => {

    return (
        <div id='more' className="container" style={{height:"auto",paddingTop:"100px",paddingBottom:"50px"}}>
            <h2 className="title1" style={{textAlign:"center"}}>{props.t('homeIconsTitle')}</h2>
            <p className="subTitle text-center">{props.t('aboutSubTitle')}</p>
            <div className="row">
                {data.map((item,i)=>
                    <div key={i} style={{paddingTop:"50px"}} className="col-lg-3 col-md-6 col-sm-6">
                        <img key={i} className="home-icon" src={item.iconSrc} alt={i}/>
                        <h4 style={{textAlign:"center"}} className="title">{props.t('icon'+i)}</h4>
                    </div>
                )}
                <div className="mx-auto pt-3" >
                    <Link className="btn btn-yellow" to='/about'>{props.t('findMore')}</Link>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(ShortAmenity)