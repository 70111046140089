import React from 'react';
import Navbar from '../../global-components/navbar';
import PageHeader from '../../global-components/page-header';
import Mission from './components/mission';
import AboutUs from './components/about-us';
import ServiceTwo from './components/service';
import Distance from './components/distance';
import Footer from '../../global-components/footer';
import Map from "./components/map";
import {withTranslation} from "react-i18next";


const About = (props) => {
    return <div>
        <Navbar />
        <PageHeader headertitle={props.t('about')} photo='house-images/Villa-Carmen-balcony.webp'/>
        <Mission />
        <AboutUs />
        <ServiceTwo />
        <Distance/>
        <Map/>
        <Footer />
    </div>
}

export default withTranslation()(About)

