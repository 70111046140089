import React, { useState } from 'react';
import Navbar from '../../global-components/navbar';
import PageHeader from '../../global-components/page-header';
import Footer from '../../global-components/footer';
import emailjs from 'emailjs-com';
import {withTranslation} from "react-i18next";
import Swal from 'sweetalert2';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";


const Contact = (props) => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const handleSubmit = (e) => {

        e.preventDefault();
        if(startDate && endDate){emailjs.sendForm('gmail', 'template_co1q5jm', e.target, 'user_XwyCiIftu69iQYroUUmPm')
            .then((result) => {
                Swal.fire("Success", "Email has been sent!", 'success')
            }, (error) => {
                Swal.fire("Error", "Unfortunately something went wrong, send us email directly", 'warning')
            });
        }else {
            Swal.fire("Fill all inputs", "Please fill all inputs", 'warning')
        }


    }

    return <div>
        <Navbar/>
        <PageHeader headertitle={props.t('contact')} photo='images/banner-contact.JPG'/>
        <div className="contact-area pd-top-100 pd-bottom-65">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="contact-form-wrap contact-form-bg mb-2">
                            <h5>{props.t('reserveStay')}</h5>
                            <p className='text-justify'>{props.t('reserveStayDesc')}</p>
                        </div>
                    </div>
                    <div className="col-lg-6">

                        <form onSubmit={handleSubmit} className="contact-form-wrap contact-form-bg">
                            <h4>{props.t('contactUs')}</h4>
                            <div className="rld-single-input">
                                <input required={true} type="text" name='name' placeholder={props.t('name')}/>
                            </div>
                            <div className='d-block d-sm-flex justify-content-between'>
                                <div className="rld-single-input w-100 pr-0 pr-sm-1">
                                    <DatePicker
                                        autoComplete="off"
                                        placeholderText='Check In'
                                        name='checkIn'
                                        className=''
                                        selected={startDate}
                                        onChange={date => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={new Date()}
                                    />
                                </div>
                                <div className="rld-single-input w-100 pl-0 pl-sm-1">
                                    <DatePicker
                                        autoComplete="off"
                                        placeholderText='Check Out'
                                        name='checkOut'
                                        className=''
                                        selected={endDate} onChange={date => setEndDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                    />
                                </div>
                            </div>
                            <div className="rld-single-input">
                                <input type="text" name='phone' placeholder={props.t('phone')} required={true}/>
                            </div>
                            <div className="rld-single-input">
                                <input type="email" name='email' placeholder={props.t('email')} required={true}/>
                            </div>
                            <div className="rld-single-input">
                                <textarea rows={10} name='message' placeholder={props.t('message')} defaultValue={""}
                                          required={true}/>
                            </div>
                            <div className="btn-wrap text-center">
                                <button className="btn btn-yellow">{props.t('send')}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row pd-top-92">
                    <div className="col-xl-4 col-sm-6">
                        <div className="single-contact-info">
                            <p><i className="fa fa-phone"/>{props.t('callUs')}</p>
                            <h5>+385 99 696 4190</h5>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="single-contact-info">
                            <p><i className="fa fa-envelope"/>{props.t('questions')}</p>
                            <h5>info@korculastay.com</h5>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="single-contact-info">
                            <p><i className="fa fa-phone"/>{props.t('address')}</p>
                            <h5>Prižba, Korčula</h5>
                            {/*<h5>CA 95046, United States</h5>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
}

export default withTranslation()(Contact)

