import React from 'react';
import Navbar from '../../global-components/navbar';
import Bannerv4 from '../../global-components/banner';
import AppermentSlider from './components/appertment-slider';
import Client from './components/client';
import Footer from '../../global-components/footer';
import NewsDetailsSection from './components/details';

const Home = () => {
    return <div >
        <Navbar />
        <Bannerv4 />
        <NewsDetailsSection />
        <AppermentSlider />
        <Client />
        <Footer />
    </div>
}

export default Home

