import React, {useCallback, useState} from 'react';
import Navbar from '../../global-components/navbar';
import Footer from '../../global-components/footer';
import PageHeader from "../../global-components/page-header";
import Gallery from "react-photo-gallery";
import Carousel, {Modal, ModalGateway} from "react-images";
import { photos } from "../../../helpers/photos"
import {withTranslation} from "react-i18next";

const HouseGallery = (props) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return <div >
        <Navbar />
        <PageHeader headertitle={props.t('gallery')} photo='images/banner-gallery.JPG'/>
        <div className="container mt-5 mb-5">
            <h3 className='custom-elegant-font font-weight-light'>{props.t('ourGallery')}</h3>
            <Gallery photos={photos} onClick={openLightbox} />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={photos.map(img => ({
                                ...img,
                                srcset: img.srcSet,
                                caption: img.title
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
        <Footer />
    </div>
}

export default withTranslation()(HouseGallery)

