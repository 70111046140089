import React from 'react';
import {withTranslation} from "react-i18next";
import bbq from '../../../../assets/icons/001-barbeque.png'
import terrace from '../../../../assets/icons/002-terrace.png'
import pool from '../../../../assets/icons/swimming-pool.png'
import sauna from '../../../../assets/icons/003-sauna.png'

const Facilities = (props) => {

    return (

            <div className='container custom-container mb-4 mt-5'>
                <div className="row">
                    <div className="col-6 col-sm-6 col-md-3 text-center">

                        <img className='custom-icon' src={terrace} alt='trc'/>
                        <p>{props.t('terrace')}</p>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3 text-center">
                        <img className='custom-icon' src={bbq} alt='trc'/>
                        <p>{props.t('grill')}</p>

                    </div>
                    <div className="col-6 col-sm-6 col-md-3 text-center">
                        <img className='custom-icon' src={sauna} alt='trc'/>
                        <p>{props.t('sauna')}</p>

                    </div>
                    <div className="col-6 col-sm-6 col-md-3 text-center">
                        <img className='custom-icon' src={pool} alt='trc'/>
                        <p>{props.t('pool')}</p>
                    </div>
                </div>
            </div>

    )
}


export default withTranslation()(Facilities)