import React from 'react';
import Navbar from "../../global-components/navbar";
import PageHeader from "../../global-components/page-header";
import PropertyDetailsSection from "./components/property-details";
import Footer from "../../global-components/footer";
import {withTranslation} from "react-i18next";

const Villa = (props) => {
    return <div>
        <Navbar />
        <PageHeader data={props.data} headertitle={props.data.title} photo={props.data.photo}/>
        <PropertyDetailsSection data={props.data} />
        <Footer />
    </div>
}

export default withTranslation()(Villa)

