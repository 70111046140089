import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import HomeV4 from './components/pages/home';
import About from './components/pages/about';
import Contact from './components/pages/contact';
import Villa from "./components/pages/villa";
import './i18n/i18next';
import {data} from "./components/pages/villa/data/data";
import Gallery from "./components/pages/gallery";

class Root extends Component {
    render() {
        return(
            <Router>
                <HashRouter basename="/">

                <Switch>
                    <Route exact path="/" component={HomeV4} />
                    <Route path="/about" component={About} />
                    <Route path="/gallery" component={Gallery} />
                    <Route path="/villa" >
                        <Villa data={data}/>
                    </Route>
                    <Route path="/contact" component={Contact} />
                </Switch>

                </HashRouter>
            </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('fisherman'));
