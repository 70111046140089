import React from 'react';
import {withTranslation} from "react-i18next";

const Service = (props) => {

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'
    let data = {
        "subtitle": `${props.t('services')}`,
        "title": `${props.t('specialFacts')}`,
        "content1": `${props.t('specialContent')}`,
        "content2": "",
        "items": [
            {
                "title": `${props.t('srvcT1')}`,
                "content1": `${props.t('srvcC1')}`,
                "content2": "",
                "icon": "assets/img/icons/wifi.png",
            },
            {
                "title": `${props.t('srvcT2')}`,
                "content1": `${props.t('srvcC2')}`,
                "content2": "",
                "icon": "assets/img/icons/parking.png",
            },
            {
                "title": `${props.t('srvcT3')}`,
                "content1": `${props.t('srvcC3')}`,
                "content2": "",
                "icon": "assets/img/icons/sunset.png",
            },
            {
                "title": `${props.t('srvcT4')}`,
                "content1": `${props.t('srvcC4')}`,
                "content2": "",
                "icon": "assets/img/icons/bbq.png",
            },
            {
                "title": `${props.t('srvcT5')}`,
                "content1": `${props.t('srvcC5')}`,
                "content2": "",
                "icon": "assets/img/icons/terrace.png",
            },
            {
                "title": `${props.t('srvcT6')}`,
                "content1": `${props.t('srvcC6')}`,
                "content2": "",
                "icon": "assets/img/icons/air-conditioner.png",
            },
            {
                "title": `${props.t('srvcT7')}`,
                "content1": `${props.t('srvcC7')}`,
                "content2": "",
                "icon": "assets/img/icons/pool.png",
            },
            {
                "title": `${props.t('srvcT8')}`,
                "content1": `${props.t('srvcC8')}`,
                "content2": "",
                "icon": "assets/img/icons/dumbbell.png",
            },
            {
                "title": `${props.t('srvcT9')}`,
                "content1": `${props.t('srvcC9')}`,
                "content2": "",
                "icon": "assets/img/icons/sauna.png",
            }
        ]
    }
    const inlineStyle = {
        backgroundImage: 'url(' + publicUrl + 'images/beach.webp)'
    }


    return <div className="service-area service-area-about mg-bottom-100 pb-xl-5 pd-0" style={inlineStyle}>
        <div className="container">
            <div className="section-title">
                <h5 className="sub-title">{data.subtitle}</h5>
                <h2 className="title">{data.title}</h2>
                <p>{data.content1}<br/> {data.content2}</p>
            </div>
            <div className="service-slider-2 row pb-xl-5 pd-0">
                {data.items.map((item, i) =>
                    <div key={i} className="item ">
                        <div className="single-intro text-center ">
                            <div className="thumb ">
                                <img src={publicUrl + item.icon} alt={imagealt} style={{height:"50px"}}/>
                            </div>
                            <div className="details">
                                <h4 className="title">{item.title}</h4>
                                <p>{item.content1} <br/> {item.content2}</p>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    </div>
}

export default withTranslation()(Service)